import LandingSection from '@/components/landing/LandingSection'
import { Mail } from 'react-feather'
import Google from 'public/img/google.svg'
import { useRouter } from 'next/router'
import { signIn } from 'next-auth/client'
import { useState, useEffect } from 'react'
import { useSession } from 'next-auth/client'
import Button from '@/components/Button'

export default function Login() {
  const [session] = useSession()
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false)
  const [isLoadingEmail, setIsLoadingEmail] = useState(false)
  const [email, setEmail] = useState('')
  const router = useRouter()
  const isLogin = router.pathname === '/login'
  const loginOrSignupText = isLogin ? 'Login' : 'Sign up'

  useEffect(() => {
    const callbackUrl = router.query.callbackUrl

    if (!session) {
      return
    }

    if (callbackUrl) {
      window.document.location = decodeURIComponent(callbackUrl)
    } else {
      router.push({
        pathname: '/videos',
      })
    }
  }, [session, router])

  function googleSignin() {
    try {
      setIsLoadingGoogle(true)

      const callbackUrl =
        router.query.callbackUrl || `${document.location.origin}/videos`

      signIn('google', {
        callbackUrl,
      })
    } catch (error) {
      console.error(error)
    }
  }

  function emailSignin(e) {
    e.preventDefault()

    if (!email) {
      return
    }

    try {
      setIsLoadingEmail(true)

      const callbackUrl =
        router.query.callbackUrl || `${document.location.origin}/videos`

      signIn('email', {
        email,
        callbackUrl,
      })
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoadingEmail(false)
    }
  }

  return (
    <LandingSection
      title={loginOrSignupText}
      canonical={router.pathname}
      description={`Welcome to Annotate.tv. ${loginOrSignupText} with Google or email to start taking notes on videos.`}
    >
      <div className="bg-gray-50 py-16 flex flex-col center px-6 lg:py-40">
        <div className="mx-auto w-full md:max-w-lg">
          <div className="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
            <Button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary clickable-opacity focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary disabled:opacity-50 disabled:pointer-events-none"
              isLoading={isLoadingGoogle}
              onClick={googleSignin}
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <Google className="h-5 w-5 text-white fill-current" />
              </span>
              {loginOrSignupText} with Google
            </Button>

            <div className="mt-6">
              <div className="mt-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300"></div>
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">Or</span>
                  </div>
                </div>
              </div>

              <form className="space-y-6" onSubmit={emailSignin}>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700 mt-3 mb-2"
                  >
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      onChange={({ target }) => setEmail(target.value.trim())}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    disabled={isLoadingEmail}
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary clickable-opacity focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary disabled:opacity-50 disabled:pointer-events-none"
                  >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                      <Mail className="h-5 w-5 text-white" />
                    </span>
                    {loginOrSignupText} with Email
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </LandingSection>
  )
}
